import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, Seo } from 'components';
import Header from 'components/Header';
import EmailForm from './components/EmailForm';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { createLead, updateQuizAnswers, updateUser } from 'state/user/effects';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import Text from 'components/Text';
import {
  useFirstVisitDate,
  usePageView,
  useQuizData,
  useTrackingQueryParams,
} from 'utils/hooks';
import { Events } from 'utils/events';
import BlackFridayBanner from '../../../components/BlackFridayBanner';
import Tracking from 'utils/tracking';
import { cliendCodeToUrl } from 'utils/cliendCodeToUrl';
import { normalizeStates } from 'utils/localization';
import { leadLanguage } from 'utils/functions';

const Container = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;

  padding: 2rem 1rem;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 28.75rem;
  width: 100%;
  flex-direction: column;

  @media ${tablet} {
  }
`;

const Title = styled.h2`
  color: #000;
  text-align: center;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.026rem;
  width: 100%;
  span {
    color: #2fbe8f;
  }
  padding-bottom: 1.5rem;
  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
  }
`;

const Footer = styled(Text)`
  color: #8e909a;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.012rem;
  @media ${tablet} {
    font-size: 0.625rem;
    letter-spacing: -0.01rem;
  }
  padding-top: 1.5rem;
`;

const EmailFormStyled = styled(EmailForm)``;

const Email: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const trackingParams = useTrackingQueryParams();
  const { isTablet } = useQuery();
  const { quiz_answers, user, geolocation, code, user_ip } = useSelector(
    (state: AppState) => state.user,
  );
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { countdownDiffInMS } = useFirstVisitDate();

  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-ef';

  const dispatch = useDispatch();

  const { goToCheckout, goToResults, goToResultsSlides } = useRouter();

  usePageView({
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  });

  const handleNextRoute = () => {
    if (variant === 'email-first') {
      return location.search.includes('tst3')
        ? goToCheckout({ discount: false })
        : goToResults();
    }
    goToCheckout({ discount: false });
  };

  const handleGetResults = async (email: string) => {
    setError('');

    setLoading(true);

    Tracking.trackCTAButton(location.pathname);

    if (!user?.email || email !== user.email) {
      dispatch(dispatch(updateUser({ email, password: '' })));
    }

    quiz_answers.quiz = quiz;

    try {
      const code =
        (await dispatch(
          createLead({
            email,
            funnel: quiz,
            quiz_answers: { ...quiz_answers, ...trackingParams },
            language: leadLanguage(quiz),
          }),
        )) ?? '';

      dispatch(updateQuizAnswers(trackingParams));

      cliendCodeToUrl(code);
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('steps')) {
        urlParams.delete('steps');
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
      }

      const userState = normalizeStates(
        geolocation?.iso_country || '',
        geolocation?.iso_state || '',
      );

      Tracking.leadCreated(
        code,
        email,
        geolocation?.iso_country,
        quiz_answers?.gender ? quiz_answers?.gender[0] : null,
        userState,
        user_ip,
      );

      goToResultsSlides();
    } catch (error) {
      setError(error?.response.data?.errors?.email ?? '');

      setLoading(false);
    }
  };

  const data = useQuizData('email');

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header />
      {data?.bfBanner ? (
        <BlackFridayBanner
          countdownDiffInMS={countdownDiffInMS}
          {...data.bfBanner}
        />
      ) : null}
      <Container>
        <ContentContainer>
          <Title dangerouslySetInnerHTML={{ __html: data?.title }} />
          <EmailFormStyled
            loading={loading}
            error={error}
            onSubmit={handleGetResults}
            continueBtnTitle={data?.continueBtnTitle}
            placeholder={data?.inputPlaceholder}
            label={data?.inputLabel}
          />
          <Footer>{data?.footer}</Footer>
        </ContentContainer>
      </Container>
    </>
  );
};

export default Email;
